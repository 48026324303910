import React from 'react';
import ReactDOM from 'react-dom/client';
import { Routes, Route, BrowserRouter } from 'react-router-dom';
import './index.css';
import App from './App';
import Repertoire from './Pages/Repertoire';
import AboutUs from './Pages/AboutUs';
import Rehearsals from './Pages/Rehearsals';
import Gigs from './Pages/Gigs';
import News from './Pages/News';
import Impressum from './Pages/Impressum';
import Board from './Pages/Board';
import Footer from './Footer';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(

  <React.StrictMode>
    <BrowserRouter>
        <Routes>
          <Route path="/" element={<App />} />
          <Route path="/home" element={<App />} />
          <Route path="/repertoire" element={<Repertoire />} />
          <Route path="/aboutUs" element={<AboutUs />} />
          <Route path="/rehearsals" element={<Rehearsals />} />
          <Route path="/gigs" element={<Gigs />} />
          <Route path="/news" element={<News />} />
          <Route path="/impressum" element={<Impressum />} />
          <Route path="/board" element={<Board />} />
        </Routes>
        <Footer />
    </BrowserRouter>
  </React.StrictMode>
);


