import React from 'react';
import './Rehearsals.css';
import NavigationBar from '../NavigationBar';

function Rehearsals() {

    return(
        <div className="Rehearsals_MainPage">
        <div className="Rehearsals_BackgroundLayer">
            <NavigationBar />

            <div className="Rehearsals_Header">
                <div className="Rehearsals_Header_Upper"></div>
                <div className="Rehearsals_Header_Lower">
                    <div className="Rehearsals_Header_Lower_Text">Proben</div>
                </div>
            </div>

            <div className="Rehearsals_Content">
                <div className="Rehearsals_Content_Text">
                <p>
                    Unsere Chorproben finden immer Montags von 19:30 - 21:00 in der Grund- und Hauptschule Dallau statt.
                </p>
                <p>
                    Seit unserem Umzug im Jahr 2015 haben wir dort unseren eigenen Raum, den wir auch mal zum Feiern nutzen können.
                </p>
                <p>
                    Gerne möchten wir dich in unserem Chor begrüßen. Das ist völlig unverbindlich. Bei uns gibt es keine Vorbedingungen und auch keine „Aufnahmeprüfung“. Also traut euch und kommt einfach zu einer Schnupperprobe vorbei. Oder sprecht mit einer unserer Sängerinnen.
                    Also bitte traut euch und ihr werdet sehen, wir sind eine tolle Truppe.
                </p>
                </div>
                <div className="Rehearsals_Content_Picture"></div>
            </div>

            <div className="Rehearsals_Map">
                <p>Zur Orientierung ein Ausschnitt des Ortsplan Elztal-Dallau mit eingefügter Markierung der Grund- und Hauptschule.<br /> Adresse des Proberaumes: Hauptstraße 8, 74834 Elztal-Dallau  </p>
                <div className="Rehearsals_Map_Picture"></div>
                <p>Der Ortsplan der Gemeinde Elztal kann unter <a href="https://www.elztal.de/gemeinde-daten/gemeinde-elztal/ortsplan" target="_blank">Ortsplan: Gemeinde Elztal</a> abgerufen werden.</p>
            </div>


        </div>
        </div>
    );

}

export default Rehearsals;