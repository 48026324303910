import React from 'react';
import './Footer.css';
import { Link } from 'react-router-dom';

function Footer() {
  return (
    <div className="Footer">
      Männergesangsverein 1862 Liederkranz Dallau e. V - {' '}
      <Link to="/impressum">Impressum</Link>
    </div>
  );
}

export default Footer;
