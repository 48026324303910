import React from 'react';
import './Board.css';
import NavigationBar from '../NavigationBar';

function Board () {

    return (
        <div className= "Board_MainPage">
             <NavigationBar />
                <div className="Board_Header">
                    <div className="Board_Header_Upper"></div>
                        <div className="Board_Header_Lower">
                            <div className="Board_Header_Lower_Text">Vorstand</div>
                        </div>
                </div>

                <div className="Board_Content">
                    <div className="Board_Content_Card">
                        <div className="Board_Content_P1"></div>
                        <div className="Board_Content_Card_Text">
                            <p className="Board_Content_Card_Text_Header">Luitgard Schneider, Vorstand</p>
                             <div className="Board_Content_Card_Text_Desc">Mein Name ist Luitgard Schneider und ich bin seit der Gründung 2006 die Sprecherin unseres Chores. <br />
                            In dieser Funktion vertrete ich den Chor nach außen und bin u.a. für die Organisation  <br />
                            unserer großen und kleinen Events zuständig. Es ist mir und meinen Vorstandskolleginnen <br />
                            ein Anliegen, dass sich alleSängerinnen - auch die „Neuen“- bei uns angenommen und integriert fühlen.<br />
                            Dass wir ein eingespieltes Team sind zeigt die Tatsache, dass sich unsere <br /> Vorstandschaft - mit Dirigent- seit
                            2006 nicht verändert hat.
                            </div>
                        </div>
                    </div>
                    <div className="Board_Content_Card">
                        <div className="Board_Content_P2"></div>
                            <div className="Board_Content_Card_Text">
                            <p className="Board_Content_Card_Text_Header">Edeltraud Schumacher, Finanzen</p>
                            <div className="Board_Content_Card_Text_Desc">
                                Ich bin Edeltraud Schumacher und für die
                                Finanzen unseres Chors zuständig.<br />
                                Seit der Gründung bin ich mit Freude dabei.<br />
                                Wir sorgen gemeinsam in der Vorstandschaft
                                dafür das der Spaß nicht zu kurz kommt!
                                </div>
                            </div>
                    </div>
                    <div className="Board_Content_Card">
                        <div className="Board_Content_P3"></div>
                            <div className="Board_Content_Card_Text">
                                 <p className="Board_Content_Card_Text_Header">Sabine Stoll, Öffentlichkeitsarbeit</p>
                                 <div className="Board_Content_Card_Text_Desc">
                                Mein Name ist Sabine Stoll. Ich bin seit 2006 bei den Singing Sisters
                                und ebenso lange schon <br />Schriftführerin.
                                Ich sorge unter anderem dafür, dass die Singing Sisters und ihre Konzerte
                                auch <br /> über Dallau hinaus bekannt werden.
                                </div>
                            </div>
                    </div>
                    <div className="Board_Content_Card">
                        <div className="Board_Content_P4"></div>
                            <div className="Board_Content_Card_Text">
                                <p className="Board_Content_Card_Text_Header">Bernd Weber, Chorleiter</p>
                                <div className="Board_Content_Card_Text_Desc">
                                Mein Name ist Bernd Weber und ich bin seit Gründung der Singing Sisters im Jahr 2006 der Chorleiter.<br />
                                Ich sorge für den „guten Ton“.<br />
                                Nach all der Zeit macht es immer noch sehr viel Spaß diesen tollen Chor leiten zu dürfen.
                                </div>
                            </div>
                    </div>


                </div>

        </div>
    );

}

export default Board;