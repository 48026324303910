import React from 'react';
import './Impressum.css';
import NavigationBar from '../NavigationBar';

function Impressum(){

    return(
        <div className="Impressum_MainPage">
        <div className="Impressum_BackgroundLayer">
            <NavigationBar />
            <div className="Impressum_Header">
                <div className="Impressum_Header_Upper"></div>
                <div className="Impressum_Header_Lower">
                    <div className="Impressum_Header_Lower_Text">Impressum</div>
                </div>
            </div>

            <div className="Impressum_Content">

               <p className="ContactAdress">
                    Singing Sisters Dallau <br />
                    Luitgard Schneider <br />
                    Obere Augartenstraße 14 <br />
                    74834 Elztal-Dallau <br />
                    Tel.: 06261 5895 <br />
                    E-Mail: singingsisters@mailbox.org
                </p>

                <p className="ImpressumText">

                    Inhalte dieser Website <br />
                    Die Inhalte dieser Website werden mit größtmöglicher Sorgfalt erstellt. Der Anbieter übernimmt jedoch keine Gewähr für die Richtigkeit, Vollständigkeit und Aktualität der bereitgestellten Inhalte. Die Nutzung der abrufbaren Inhalte erfolgt auf eigene Gefahr des Nutzers. Namentlich gekennzeichnete Beiträge geben die Meinung des jeweiligen Autors und nicht immer die Meinung des Anbieters wieder.<br /><br />
                    Verfügbarkeit der Website <br />
                    Der Anbieter wird sich bemühen, den Dienst möglichst unterbrechungsfrei zum Abruf anzubieten. Auch bei aller Sorgfalt können aber Ausfallzeiten nicht ausgeschlossen werden. Der Anbieter behält sich das Recht vor, sein Angebot jederzeit zu ändern oder einzustellen. <br /><br />
                    Externe Links <br />
                    Diese Website enthält Verknüpfungen zu Websites Dritter ("externe Links"). Diese Websites unterliegen der Haftung der jeweiligen Betreiber. Der Anbieter hat bei der erstmaligen Verknüpfung der externen Links die fremden Inhalte daraufhin überprüft, ob etwaige Rechtsverstöße bestehen. Zu dem Zeitpunkt waren keine Rechtsverstöße ersichtlich. Der Anbieter hat keinerlei Einfluss auf die aktuelle und zukünftige Gestaltung und auf die Inhalte der verknüpften Seiten. Das Setzen von externen Links bedeutet nicht, dass sich der Anbieter die hinter dem Verweis oder Link liegenden Inhalte zu Eigen macht. Eine ständige Kontrolle dieser externen Links ist für den Anbieter ohne konkrete Hinweise auf Rechtsverstöße nicht zumutbar. Bei Kenntnis von Rechtsverstößen werden jedoch derartige externe Links unverzüglich gelöscht.<br /><br />
                    Werbeanzeigen<br />
                    Für den Inhalt der Werbeanzeigen ist der jeweilige Autor verantwortlich, ebenso wie für den Inhalt der beworbenen Website. Die Darstellung der Werbeanzeige stellt keine Akzeptanz durch den Anbieter dar.<br /><br />
                    Kein Vertragsverhältnis<br />
                    Mit der Nutzung der Website des Anbieters kommt keinerlei Vertragsverhältnis zwischen dem Nutzer und dem Anbieter zustande. Insofern ergeben sich auch keinerlei vertragliche oder quasivertragliche Ansprüche gegen den Anbieter. Für den Fall, dass die Nutzung der Website doch zu einem Vertragsverhältnis führen sollte, gilt rein vorsorglich nachfolgende Haftungsbeschränkung: Der Anbieter haftet für Vorsatz und grobe Fahrlässigkeit sowie bei Verletzung einer wesentlichen Vertragspflicht (Kardinalpflicht). Der Anbieter haftet unter Begrenzung auf Ersatz des bei Vertragsschluss vorhersehbaren vertragstypischen Schadens für solche Schäden, die auf einer leicht fahrlässigen Verletzung von Kardinalpflichten durch ihn oder eines seiner gesetzlichen Vertreter oder Erfüllungsgehilfen beruhen. Bei leicht fahrlässiger Verletzung von Nebenpflichten, die keine Kardinalpflichten sind, haftet der Anbieter nicht. Die Haftung für Schäden, die in den Schutzbereich einer vom Anbieter gegebenen Garantie oder Zusicherung fallen sowie die Haftung für Ansprüche aufgrund des Produkthaftungsgesetzes und Schäden aus der Verletzung des Lebens, des Körpers oder der Gesundheit bleibt hiervon unberührt.<br /><br />
                    Urheberrecht<br />
                    Die auf dieser Website veröffentlichten Inhalte und Werke sind urheberrechtlich geschützt. Jede vom deutschen Urheberrecht nicht zugelassene Verwertung bedarf der vorherigen schriftlichen Zustimmung des jeweiligen Autors oder Urhebers. Dies gilt insbesondere für Vervielfältigung, Bearbeitung, Übersetzung, Einspeicherung, Verarbeitung bzw. Wiedergabe von Inhalten in Datenbanken oder anderen elektronischen Medien und Systemen. Inhalte und Beiträge Dritter sind dabei als solche gekennzeichnet. Die unerlaubte Vervielfältigung oder Weitergabe einzelner Inhalte oder kompletter Seiten ist nicht gestattet und strafbar. Lediglich die Herstellung von Kopien und Downloads für den persönlichen, privaten und nicht kommerziellen Gebrauch ist erlaubt. Links zur Website des Anbieters sind jederzeit willkommen und bedürfen keiner Zustimmung durch den Anbieter der Website. Die Darstellung dieser Website in fremden Frames ist nur mit Erlaubnis zulässig.<br /><br />
                    Anwendbares Recht<br />
                    Es gilt ausschließlich das maßgebliche Recht der Bundesrepublik Deutschland.<br /><br />
                    Besondere Nutzungsbedingungen<br />
                    Soweit besondere Bedingungen für einzelne Nutzungen dieser Website von den vorgenannten Punkten (1) bis (4) abweichen, wird an entsprechender Stelle ausdrücklich darauf hingewiesen. In diesem Falle gelten im jeweiligen Einzelfall die besonderen Nutzungsbedingungen.<br /><br />
                    Bildnachweis<br />
                    Gemäß der Nutzungsbedingungen finden Sie hier den Bildnachweis der Bilder, Illustrationen und Fotos:<br />
                    eigene Dartstellungen<br /><br />
                    Datenschutz<br />
                    Wir nehmen den Schutz Ihrer personenbezogenen Daten ernst und beachten die Regeln der anwendbaren Datenschutzgesetze. Es ist uns wichtig, dass Sie jederzeit wissen, welche personenbezogenen Daten während Ihres Besuchs auf unserer Webseite und bei Nutzung unserer Angebote erhoben werden und wie wir sie verwenden.
                    Personenbezogene Daten sind Einzelangaben über persönliche und sachliche Verhältnisse einer bestimmten oder bestimmbaren natürlichen Person.
                    Mit den nachfolgenden Hinweisen möchten wir Sie über die Erhebung und Verarbeitung Ihrer Daten auf unseren Webseiten informieren:
                    Datenschutzrechtlich verantwortliche Stelle<br />
                    Die datenschutzrechtlich verantwortliche Stelle ist im Impressum unserer Webseite näher bezeichnet. Sprechen Sie uns bei Fragen gerne über die dort hinterlegten Kontaktdaten an.<br />
                    Rechtsgrundlagen für die Verarbeitung personenbezogener Daten<br />
                    Soweit wir für Verarbeitungsvorgänge personenbezogener Daten eine Einwilligung der betroffenen Person einholen, dient das BDG als Rechtsgrundlage für die Verarbeitung personenbezogener Daten.
                    Bei der Verarbeitung von personenbezogenen Daten, die zur Erfüllung eines Vertrages, dessen Vertragspartei die betroffene Person ist, erforderlich ist, dient das BDG als Rechtsgrundlage. Dies gilt auch für Verarbeitungsvorgänge, die zur Durchführung vorvertraglicher Maßnahmen erforderlich sind.
                    Soweit eine Verarbeitung personenbezogener Daten zur Erfüllung einer rechtlichen Verpflichtung erforderlich ist, der die datenschutzrechtlich verantwortliche Stelle unterliegt, dient das BDG als Rechtsgrundlage.
                    Für den Fall, dass lebenswichtige Interessen der betroffenen Person oder einer anderen natürlichen Person eine Verarbeitung personenbezogener Daten erforderlich machen, dient das BDG als Rechtsgrundlage.
                    Ist die Verarbeitung zur Wahrung eines berechtigten Interesses der datenschutzrechtlich verantwortlichen Stelle oder eines Dritten erforderlich und überwiegen die Interessen, Grundrechte und Grundfreiheiten des Betroffenen das erstgenannte Interesse nicht, so dient das BDG als Rechtsgrundlage für die Verarbeitung.
                    Datenlöschung und Speicherdauer<br />
                    Die personenbezogenen Daten der betroffenen Person werden gelöscht oder gesperrt, sobald der Zweck der Speicherung entfällt. Eine Speicherung kann darüber hinaus dann erfolgen, wenn dies durch den kirchlichen, europäischen oder nationalen Gesetzgeber in kirchlichen oder unionsrechtlichen Verordnungen, Gesetzen oder sonstigen Vorschriften, denen der Verantwortliche unterliegt, vorgesehen wurde. Eine Sperrung oder Löschung der Daten erfolgt auch dann, wenn eine durch die genannten Normen vorgeschriebene Speicherfrist abläuft, es sei denn, dass eine Erforderlichkeit zur weiteren Speicherung der Daten durch einen Vertragsabschluss oder eine Vertragserfüllung bedingt ist.<br /><br />
                    Rechte der betroffenen Person<br />
                    Sie haben nach dem BDG folgende Rechte hinsichtlich der Sie betreffenden personenbezogenen Daten:<br />
                    Recht auf Auskunft,<br />
                    Recht auf Berichtigung,<br />
                    Recht auf Löschung,<br />
                    Recht auf Einschränkung der Verarbeitung,<br />
                    Recht auf Widerspruch gegen die Verarbeitung,<br />
                    Recht auf Datenübertragbarkeit.<br />
                    Sie haben zudem das Recht, sich bei einer Datenschutz-Aufsichtsbehörde bzw. der Datenschutzaufsicht (BDG) über die Verarbeitung Ihrer personenbezogenen Daten zu beschweren.<br />
                    Beschwerderecht bei der Datenschutz-Aufsichtsbehörde<br />
                    Sie haben das Recht auf Beschwerde bei einer Aufsichtsbehörde (BDG) unbeschadet eines anderweitigen Rechtsbehelfs das Recht auf Beschwerde bei der im Impressum genannten Person, wenn sie der Ansicht ist, dass die Verarbeitung der sie betreffenden personenbezogenen Daten gegen die Vorschriften des BDG oder gegen andere Datenschutzvorschriften verstößt.<br /><br />
                    Kontaktanfragen<br />
                    Wenn Sie uns per Kontaktformular Anfragen zukommen lassen, werden Ihre Angaben aus dem Anfrageformular inklusive der von Ihnen dort angegebenen Kontaktdaten zwecks Bearbeitung der Anfrage und für den Fall von Anschlussfragen bei uns gespeichert. Diese Daten geben wir nicht ohne Ihre Einwilligung weiter.
                    Die Verarbeitung dieser Daten erfolgt auf Grundlage des BDG, sofern Ihre Anfrage mit der Erfüllung eines Vertrags zusammenhängt oder zur Durchführung vorvertraglicher Maßnahmen erforderlich ist.
                    In allen übrigen Fällen beruht die Verarbeitung auf unserem berechtigten Interesse an der effektiven Bearbeitung der an uns gerichteten Anfragen (BDG) oder auf Ihrer Einwilligung (BDG) sofern diese abgefragt wurde; die Einwilligung ist jederzeit widerrufbar.
                    Die von Ihnen im Kontaktformular eingegebenen Daten verbleiben bei uns, bis Sie uns zur Löschung auffordern, Ihre Einwilligung zur Speicherung widerrufen oder der Zweck für die Datenspeicherung entfällt (z. B. nach abgeschlossener Bearbeitung Ihrer Anfrage). Zwingende gesetzliche Bestimmungen – insbesondere Aufbewahrungsfristen – bleiben unberührt.<br />
                    Registrierung für den abgeschlossenen Bereich<br />
                    Wir erheben und verwenden die erforderlichen Registrierungsangaben (wie z.B. Ihre E-Mail-Adresse bzw. Ihren Benutzernamen) sowie Ihre in diesem Zusammenhang mitgeteilten Daten zur Einrichtung und Nutzung unseres abgeschlossenen Online-Bereichs und seiner Funktionen.<br /><br />
                    Webhosting<br />
                    Diese Website wird extern gehostet. Die personenbezogenen Daten, die auf dieser Website erfasst werden, werden auf den Servern des Hosters / der Hoster gespeichert. Hierbei kann es sich v. a. um IP-Adressen, Kontaktanfragen, Meta- und Kommunikationsdaten, Vertragsdaten, Kontaktdaten, Namen, Websitezugriffe und sonstige Daten, die über eine Website generiert werden, handeln.
                    Das externe Hosting erfolgt zum Zwecke der Vertragserfüllung gegenüber unseren potenziellen und bestehenden Kunden (BDG) und im Interesse einer sicheren, schnellen und effizienten
                    Bereitstellung unseres Online-Angebots durch einen professionellen Anbieter (BDG).
                    Unser(e) Hoster wird bzw. werden Ihre Daten nur insoweit verarbeiten, wie dies zur Erfüllung seiner Leistungspflichten erforderlich ist und unsere Weisungen in Bezug auf diese Daten befolgen.<br />
                    Wir setzen folgende(n) Hoster ein:<br />
                    IONOS SE <br />
                    Elgendorfer Str. 57 <br />
                    56410 Montabaur <br /><br />
                    Auftragsverarbeitung <br />
                    Wir haben einen Vertrag über Auftragsverarbeitung (AVV) mit dem oben genannten Anbieter geschlossen. Hierbei handelt es sich um einen datenschutzrechtlich vorgeschriebenen Vertrag, der gewährleistet, dass dieser die personenbezogenen Daten unserer Websitebesucher nur nach unseren Weisungen und unter
                    Einhaltung des BDG verarbeitet.<br /><br />
                    Protokolldaten<br />
                    Bei jedem Zugriff eines Nutzers auf eine Seite aus diesem Webseiten-Angebot werden Daten über diesen Vorgang temporär in einer Protokolldatei gespeichert. Die temporäre Speicherung dieser sog. Server-Logdaten ist zur Erbringung des Dienstes aus technischen Gründen und danach zur Sicherstellung der Systemsicherheit erforderlich. Die Daten werden nach 30 Tagen gelöscht.<br /><br />
                    Cookies<br />
                    Um unseren Internetauftritt benutzerfreundlich zu gestalten und optimal auf Ihre Bedürfnisse abzustimmen sowie um die erforderlichen technischen Funktionen zu ermöglichen und sicherzustellen, werden auf unserer Webseite in einigen Bereichen Cookies eingesetzt, ggfs. auch von Drittanbietern.
                    "Cookies" sind kleine Textdateien, die lokal auf der Festplatte Ihres Endgeräts (Computer, Smartphone, Tablet) abgelegt und für einen späteren Abruf bereitgehalten werden. Nach Beendigung Ihres Besuchs und Beenden des von Ihnen verwendeten Internetbrowsers werden die meisten Cookies automatisch gelöscht (sog. Sitzungs-Cookies). Andere Cookies können für einen längeren Zeitraum auf Ihrem Endgerät verbleiben und ermöglichen, Ihren Browser beim nächsten Besuch wiederzuerkennen (persistente Cookies).
                    Die Installation von Cookies können Sie wie unter „Einwilligung und Ablehnung von Cookies“ beschrieben, durch Änderung der Einstellungen Ihrer Browser Software bestimmen. Allerdings können Sie dann unter Umständen nicht mehr alle Funktionen unserer Webseite nutzen.<br />
                    Einwilligung und Ablehnung von Cookies<br />
                    Die Einwilligung oder Ablehnung von Cookies können Sie über die Einstellungen Ihres Webbrowsers erklären. Sie können Ihren Webbrowser so einstellen, dass er Sie beim Setzen von Cookies benachrichtigt oder Cookies generell ablehnt. Allerdings können Sie dann nicht mehr alle Funktionen unserer Webseite nutzen. Über die folgenden Links können Sie sich über diese Möglichkeit für die am meisten verwendeten Browser informieren:<br />
                    Internet Explorer: https://support.microsoft.com/de-de/help/17442/windows-internet-explorer-delete-manage-cookies<br />
                    Firefox: http://support.mozilla.org/de/kb/cookies-informationen-websites-auf-ihrem-computer<br />
                    Google Chrome: http://support.google.com/chrome<br />
                    Safari: https://support.apple.com/de-de/guide/safari/sfri11471/mac<br />
                    Opera: https://help.opera.com/de/latest/web-preferences/<br /><br />
                    Google Web Fonts (lokales Hosting)<br />
                    Diese Seite nutzt zur einheitlichen Darstellung von Schriftarten sogenannte Web Fonts, die von Google bereitgestellt werden. Die Google Fonts sind lokal installiert. Eine Verbindung zu Servern von Google findet dabei nicht statt.<br />
                    Weitere Informationen zu Google Web Fonts finden Sie unter<br />
                    https://developers.google.com/fonts/faq und in der Datenschutzerklärung von Google:<br />
                    https://policies.google.com/privacy <br /><br />
                    Einsatz von YouTube-Videos<br />
                    Diese Website bindet Videos der Plattform YouTube ein. Betreiber der Seiten ist die Google Ireland Limited („Google“), Gordon House, Barrow Street, Dublin 4, Irland.
                    Wir nutzen YouTube im erweiterten Datenschutzmodus. Dieser Modus bewirkt laut YouTube, dass YouTube keine Informationen über die Besucher auf dieser Website speichert, bevor diese sich das Video ansehen. Die Weitergabe von Daten an YouTube-Partner wird durch den erweiterten Datenschutzmodus hingegen nicht zwingend ausgeschlossen. So stellt YouTube – unabhängig davon, ob Sie sich ein Video ansehen – eine Verbindung zum Google DoubleClick-Netzwerk her.
                    Zum Schutz Ihrer Privatsphäre müssen Sie die Videos auf unseren Seiten zunächst aktivieren. Sobald Sie das Video aktivieren, wird eine Verbindung zu den Servern von YouTube hergestellt. Dabei wird dem YouTube-Server mitgeteilt, welche unserer Seiten Sie besucht haben. Wenn Sie in Ihrem YouTube-Account eingeloggt sind, ermöglichen Sie YouTube, Ihr Surfverhalten direkt Ihrem persönlichen Profil zuzuordnen. Dies können Sie verhindern, indem Sie sich aus Ihrem YouTube-Account ausloggen. Die weitere Verarbeitung dieser Informationen liegt in der Verantwortung von YouTube / Google.  Die Datenschutzerklärung von Google finden Sie unter https://policies.google.com/privacy
                    Des Weiteren kann YouTube nach Starten eines Videos verschiedene Cookies auf Ihrem Endgerät speichern oder vergleichbare Wiedererkennungstechnologien (z.B. Device-Fingerprinting) einsetzen. Auf diese Weise kann YouTube Informationen über Besucher dieser Website erhalten. Diese Informationen werden u. a. verwendet, um Videostatistiken zu erfassen, die Anwenderfreundlichkeit zu verbessern und Betrugsversuchen vorzubeugen.
                    Gegebenenfalls können nach dem Start eines YouTube-Videos weitere Datenverarbeitungsvorgänge ausgelöst werden, auf die wir keinen Einfluss haben.
                    Mit der Aktivierung erteilen Sie Ihre Einwilligung, dass Youtube auf dem von Ihnen verwendeten Endgerät Cookies setzt, die auch einer Analyse des Nutzungsverhaltens zu Marktforschungs- und Marketing-Zwecken dienen können. Näheres zur Cookie-Verwendung durch Youtube finden Sie in der Cookie-Policy von Google unter https://policies.google.com/technologies/types?hl=de
                    Verwendung von Teilen-Buttons von Facebook, X, Linkedln, Mail, WhatsApp und Threema unter Verwendung der Shariff-Lösung
                    Auf allen Nachrichten- und Termin-Detailseiten werden Teilen-Buttons von Facebook, X, Linkedln, Mail, WhatsApp und Threema verwendet.
                    Um den Schutz Ihrer Daten beim Besuch unserer Website zu erhöhen,  sind die Plugins nicht uneingeschränkt, sondern lediglich unter  Verwendung  eines  HTML-Links  (sogenannte  „Shariff-Lösung“  von c‘t)  in  die  Seite eingebunden.  Diese Einbindung gewährleistet, dass  beim  Aufruf  einer Seite unseres Webauftritts, die solche Plugins enthält, noch keine Verbindung mit den Servern des Anbieters des  jeweiligen sozialen Netzwerks hergestellt wird.  Klicken Sie auf einen  der  Buttons, öffnet sich ein neues Fenster Ihres Browsers und ruft die Seite des jeweiligen Diensteanbieters auf, auf der Sie  (ggf.  nach  Eingabe  Ihrer  Login-Daten)  z.B.  den Like-  oder Share-Button  betätigen können.
                    Das Aktivieren des Plugins stellt eine Einwilligung im Sinne des BDG dar. Diese Einwilligung können Sie jederzeit mit Wirkung für die Zukunft widerrufen.<br /><br />
                    Zweck und Umfang der Datenerhebung und die weitere Verarbeitung und Nutzung der Daten
                    durch die  Anbieter  auf  deren  Seiten  sowie  Ihre  diesbezüglichen  Rechte  und  Einstellungsmöglichkeiten zum Schutz Ihrer Privatsphäre entnehmen Sie bitte den Datenschutzhinweisen der Anbieter:<br />
                        https://de-de.facebook.com/policy.php<br />
                        https://twitter.com/de/privacy<br />
                        https://de.linkedin.com/legal/privacy-policy?<br />
                        https://threema.ch/de/privacy<br />
                        https://www.whatsapp.com/legal/privacy-policy-eea?lang=de<br />
                </p>

            </div>

        </div>
        </div>
    );

}

export default Impressum;