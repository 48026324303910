import React from 'react';
import './AboutUs.css';
import NavigationBar from '../NavigationBar';

function AboutUs(){


    return(

        <div className="AboutUs_MainPage">
        <div className="aboutUs_BackgroundLayer">
            <NavigationBar />

            <div className="AboutUs_Header">
                <div className="AboutUs_Header_Upper"></div>
                <div className="AboutUs_Header_Lower">
                    <div className="AboutUs_Header_Lower_Text">Über Uns</div>
                </div>
            </div>

            <div className="AboutUs_Content">
                <p>
                    Wie alles begann!
                </p>
                <p>
                    Man schrieb das Jahr 2006 und ein Ereignis warf bereits seine Schatten voraus.
                </p>
                <p>
                    Im Jahr 2007 sollte das 145jährige Jubiläum des MGV Liederkranzes Dallau gefeiert werden. Nun kam es einem Sänger in den Sinn anlässlich dieses Jubiläums dem Chor eine Überraschung zu präsentieren.
                    Kurzerhand wurde ein Rundruf bei allen Sängerehefrauen getätigt, mit der Absicht einen Projektchor speziell für das anstehende Fest zu gründen. Schnell waren 15 bis 20 Sängerinnen gefunden, unter ihnen viele,
                    die noch nie mit Chorgesang vertraut waren. Im September 2006 traf man sich das erste Mal und studierte zwei Liedsätze ein, die dann beim Festakt im April 2007 zur Aufführung kamen.
                </p>
                <p>
                    Nach diesem Ereignis hatten die Frauen so viel Freude an den Singstunden gefunden, dass aus dem Projektchor kurzerhand ein „richtiger Frauenchor“ wurde.
                </p>
                <p>
                    Die Singing Sisters waren geboren!
                    <div className="AboutUs_Pic_ContainerGroup">
                        <div className="JP_1"></div>
                        <div className="JP_3"></div>
                    </div>
                </p>
                 <p>
                    Der besagte Sänger, der aus einer Schnapslaune heraus diesen Chor ins Leben rief, war kein anderer als der heutige Dirigent Bernd Weber. Eine Ausbildung zum Chorleiter im Nebenberuf,
                    gepaart mit Autodidaktik und unfassbarem Engagement, verhalfen ihm dazu, den Chor in den letzten 17 Jahren erfolgreich zu leiten und die Singstunden mit inspirierender Kreativität zu gestalten.
                 </p>
                 <p>
                    Wir, die Singing Sisters sind eine Gemeinschaft von derzeit 26 Sängerinnen, die aus Freude am Singen zusammenkommen. Wir setzen uns erreichbare Ziele und singen mit dem Anspruch unser musikalisches Niveau immer weiter zu verbessern.
                    Wir achten aber gemeinsam mit unserem Chorleiter darauf, dass dies nicht in Druck ausartet auch wenn die Zeit vor Auftritten immer knapp wird, aber das kennen wohl alle Chöre. Die Prämisse ist, es muss Spaß machen!
                 </p>
                 <p>
                    Unsere Liedauswahl ist breit gefächert und reicht von klassischen Chören, Balladen bis zu modernen Arrangements von Rock- und Popsongs.
                    Gerne singen wir die Lieder in der jeweiligen Originalsprache, z. B. Schwedisch, Englisch und selbstverständlich auch Deutsch. Auch Texte die keiner Sprache zugeordnet werden können, wie z. B. Adiemus von Karl Jenkins, sind uns nicht fremd.
                 </p>
                 <p className="JP_2_Text">
                    <div>
                    Aber die Singing Sisters können auch Party machen! So fand zum 10jährigen Jubiläum im Jahr 2016 ein großes Open-Air-Konzert auf dem Schlossplatz in Dallau statt.
                    „Singing Sisters and Friends“ lautete das Motto dieses Events, an dem viele renommierte Chöre der Umgebung teilnahmen. Zwei weitere OpenAir Konzerte folgten in den Jahren 2018 und 2023.
                    Bei der Bevölkerung und allen Liebhabern der modernen Chormusik haben sich diese sommerlichen Konzerte auf dem Schlossplatz etabliert und sind ein Garant für einen großartigen Musikgenuss!
                    Die lebendige Atmosphäre und die vielfältigen musikalischen Darbietungen der Singing Sisters und ihrer befreundeten Chöre schaffen eine einzigartige Verbindung zwischen Kultur und Gemeinschaft, die die Zuschauer immer wieder aufs Neue begeistert.
                    </div>
                    <div className="JP_2"></div>
                 </p>
                 <p>
                    Einen Ausschnitt hiervon könnt ihr auf der Home-Seite sehen.
                 </p>
                 <p className="FP_1_Text">
                 <div className="FP_1"></div>
                 <div>
                    Gerne umrahmen wir auch Feierlichkeiten und Hochzeiten sowie Veranstaltungen der Gemeinde und von Firmen mit unseren Liedbeiträgen. Auch im sozialen Bereich engagieren wir uns im Rahmen der musikalischen Gestaltung von Seniorennachmittagen und Auftritten
                    im örtlichen Seniorenheim zur Weihnachtszeit. <br />Gerne feiern wir bei Gelegenheit auch selbst kleinere Feste, wobei man immer mit humorvollen Überraschungen unserer Sängerinnen rechnen kann.
                 </div>

                 </p>
                 <p>
                    Im juristischen Sinne sind wir eine Abteilung des Männergesangvereins 1862 Dallau, unter dessen Rechtsmantel wir unsere Heimat gefunden haben. Dabei sind wir frei und eigenständig in dem was wir tun. Wir haben eine eigene unabhängige Vorstandschaft.
                    Dabei ist die freundschaftliche und vertrauensvolle Zusammenarbeit der beiden Chöre die Basis der Zusammenarbeit. Man hilft und unterstützt sich gegenseitig. Wir glauben, nein wir wissen das macht einen gesunden Verein und eine förderliche Gemeinschaft aus.
                 </p>
                 <p>
                    Unsere Förderer helfen dabei den „Betrieb“ am laufen zu halten und unterstützen uns ganz praktisch und auch finanziell, wenn es mal wieder darum geht Veranstaltungen vorzubereiten und durchzuführen.
                    Dafür sind wir den Unternehmen und unserer Gemeindeverwaltung sehr dankbar.
                 </p>

            </div>

        </div>
        </div>

    );


}

export default AboutUs;