import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import './NavigationBar.css';

const NavigationBar: React.FC = () => {

    const navigate = useNavigate();
    const location = useLocation();

    let isHomePage = (location.pathname === '/home') || (location.pathname === '/');
    let color : string = isHomePage ? 'black' : 'white';

    const handleClickNavigation = (btn: string) => {
        switch (btn){
            case "home" : navigate('/home'); break;
            case "news" : navigate('/news'); break;
            case "gigs" : navigate('/gigs'); break;
            case "repertoire" : navigate('/repertoire'); break;
            case "aboutUs" : navigate('/aboutUs'); break;
            case "rehearsals" : navigate('/rehearsals'); break;
            case "board" : navigate('/board'); break;
            default : break;
        }
    };

    return (
        <div className="NavBar">
            <div className="NavBar_Buttons">
                <button onClick={() => handleClickNavigation("home")}>Home</button>
                <button onClick={() => handleClickNavigation("news")}>Aktuelles</button>
                <button onClick={() => handleClickNavigation("gigs")}>Auftritte</button>
                <button onClick={() => handleClickNavigation("repertoire")}>Repertoire</button>
                <button onClick={() => handleClickNavigation("aboutUs")}>Über Uns</button>
                <button onClick={() => handleClickNavigation("rehearsals")}>Proben</button>
                <button onClick={() => handleClickNavigation("board")}>Vorstand</button>
            </div>
            <div className="NavBarLinks_Container">
                <a className="NavBar_Links" href="https://www.facebook.com/mgvliederkranzdallau/" target="_blank"><svg width="3vw" height="3vh" fill={ color } viewBox="0 0 16 16"><path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951"/> </svg></a>
                <a className="NavBar_Links" href="https://www.youtube.com/watch?v=ozomuIZ4ki4" target="_blank"><svg width="3vw" height="3vh" fill={ color } viewBox="0 0 16 16"><path d="M8.051 1.999h.089c.822.003 4.987.033 6.11.335a2.01 2.01 0 0 1 1.415 1.42c.101.38.172.883.22 1.402l.01.104.022.26.008.104c.065.914.073 1.77.074 1.957v.075c-.001.194-.01 1.108-.082 2.06l-.008.105-.009.104c-.05.572-.124 1.14-.235 1.558a2.01 2.01 0 0 1-1.415 1.42c-1.16.312-5.569.334-6.18.335h-.142c-.309 0-1.587-.006-2.927-.052l-.17-.006-.087-.004-.171-.007-.171-.007c-1.11-.049-2.167-.128-2.654-.26a2.01 2.01 0 0 1-1.415-1.419c-.111-.417-.185-.986-.235-1.558L.09 9.82l-.008-.104A31 31 0 0 1 0 7.68v-.123c.002-.215.01-.958.064-1.778l.007-.103.003-.052.008-.104.022-.26.01-.104c.048-.519.119-1.023.22-1.402a2.01 2.01 0 0 1 1.415-1.42c.487-.13 1.544-.21 2.654-.26l.17-.007.172-.006.086-.003.171-.007A100 100 0 0 1 7.858 2zM6.4 5.209v4.818l4.157-2.408z"/> </svg></a>
                <a className="NavBar_Links" href="https://twitter.com/" target="_blank"><svg width="3vw" height="3vh" fill={ color } viewBox="0 0 16 16"><path d="M12.6.75h2.454l-5.36 6.142L16 15.25h-4.937l-3.867-5.07-4.425 5.07H.316l5.733-6.57L0 .75h5.063l3.495 4.633L12.601.75Zm-.86 13.028h1.36L4.323 2.145H2.865z"/> </svg></a>
            </div>
        </div>
    );
};

export default NavigationBar;
