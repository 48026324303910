import React from 'react';
import './Repertoire.css';
import songs, { Song } from '../SongList';
import NavigationBar from '../NavigationBar';

function Repertoire(){

  return (
    <div className="Repertoire_MainPage">
        <NavigationBar />
        <div className="Repertoire_Header">
            <div className="Repertoire_Header_Upper"></div>
            <div className="Repertoire_Header_Lower">
                <div className="Repertoire_Header_Lower_Text">Repertoire</div>
            </div>
        </div>

        <div className="Repertoire_TopSongs">
            <div className="Song_Card">
                <div className="Song_Card_Picture sc_adiemus"></div>
                <div className="Song_Card_Text">
                    Adiemus
                    <p className="Song_Card_Para">"Adiemus" ist lateinisch und bedeutet in etwa "wir werden aufsuchen", sowie "Audiemus" für "wir werden hören" steht. Das Lied war jedoch eine reine Fantasiewortschöpfung von Karl Jenkis.</p>
                </div>
            </div>
            <div className="Song_Card">
                <div className="Song_Card_Picture sc_gabriella"></div>
                <div className="Song_Card_Text">
                    Gabriellas Song
                    <p className="Song_Card_Para">„Gabriellas Sång“ stammt aus dem schwedischen Kinofilm „Wie im Himmel“, der seit 2005 die Herzen der Menschen berührt.</p>
                </div>
            </div>
            <div className="Song_Card">
                <div className="Song_Card_Picture sc_halleluja"></div>
                <div className="Song_Card_Text">
                    Halleluja
                    <p className="Song_Card_Para">Auch in unserem Repertoire findet sich das weltberühmte Lied von Leonard Cohen wieder, welches er 1984 auf dem Album "Various Positions" veröffentlichte. </p>
                </div>
            </div>
            <div className="Song_Card">
                <div className="Song_Card_Picture sc_chocolate"></div>
                <div className="Song_Card_Text">
                    Ich will keine Schokolade
                    <p className="Song_Card_Para">Unter dem Titel Percolator wurde dieses Lied in den späten 50er Jahren in den USA geschrieben. Bekannt wurde der Song erst ein Jahrzehnt später durch die deutsche Fassung von Trude Herr.</p>
                </div>
            </div>
            <div className="Song_Card">
                <div className="Song_Card_Picture sc_tango"></div>
                <div className="Song_Card_Text">
                    Criminal Tango
                    <p className="Song_Card_Para">Kriminal-Tango ist ein Schlager aus dem Jahr 1959. Im Original wurde er vom Italiener Piero Trombetta komponiert und veröffentlicht.</p>
                </div>
            </div>
            <div className="Song_Card">
                <div className="Song_Card_Picture sc_nessaia"></div>
                <div className="Song_Card_Text">
                    Nessaias Lied
                    <p className="Song_Card_Para">Die Kernaussage dieses Liedes von Peter Mafia stößt nachwievor auf Resonanz. Der Song begleitet musikalisch die Abenteuer des Drachen Tabaluga.</p>
                </div>
            </div>
        </div>

        <div className="Repertoire_Further">
            <details className="Repertoire_Further_Header">
                <summary>weitere Lieder unseres Repertoires</summary>
                <p>
                    <div className="Repertoire_Further_List">
                        <ul className="Repertoire_Further_List_Ul">
                            {songs.map((song, index) => (
                                <li>{song.title}</li>
                            ))}
                        </ul>
                    </div>
                </p>
            </details>

        </div>




    </div>
  );
};

export default Repertoire;
