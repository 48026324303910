import React from 'react';
import './Gigs.css';
import NavigationBar from '../NavigationBar';

function Gigs(){


    interface Gig {
        date: string;
        time: string;
        title: string;
        description: string;
      }

      const gigs: Gig[] = [
        {
          date: '26.12.2024',
          time: '10:15',
          title: 'Weihnachtgottesdienst',
          description: 'Begleitung des Weihnachtsgottesdienstes in der katholischen Kirche Dallau.',
        },
        {
            date: '21.06.2024',
            time: '17:30',
            title: 'Mittsommernacht',
            description: 'Feier zur Sommersonnwende mit zünftigen Speisen und Getränken',
        },
        {
            date: '26.12.2023',
            time: '10:00',
            title: 'Weihnachtsgottesdienst',
            description: 'Musikalische Begleitung des Weihnachtsgottesdienstes in der evangelischen Kirche Elztal-Dallau.',
        },
        {
            date: '10.12.2023',
            time: '12:00',
            title: 'Weihnachtskonzert',
            description: 'Ein Weihnachtskonzert gemeinsam mit dem Männergesangsverein MGV Liederkranz und dem MGV Schwarzach in der katholischen Kirche Dallau.',
        },
        {
            date: '03.12.2023',
            time: '18:00',
            title: 'Weihnachtsmarkt der katholischen Kirche',
            description: 'Auftritt auf dem Weihnachtsmarkt der katholischen Kirche in Elztal-Dallau.',
        },
        {
            date: '05.11.2023',
            time: '16:30',
            title: 'Seniorennachmittag',
            description: 'Musikalische Begleitung des Seniorennachmittags im Dorfgemeinschaftshaus Dallau.',
        },
        {
            date: '15.07.2023',
            time: '19:00',
            title: '3. Dallauer Musiknacht',
            description: 'Ein Konzert auf dem Schlossplatz Dallau mit diversen Gastchören aus der Region.',
        },
      ];


    return(
        <div className="Gigs_MainPage">
            <NavigationBar />
            <div className="Gigs_Header">
                <div className="Gigs_Header_Upper"></div>
                <div className="Gigs_Header_Lower">
                    <div className="Gigs_Header_Lower_Text">Auftritte</div>
                </div>
            </div>
            <div className="currentGig"></div>
            <div className="Gigs_Content">
              <div className="Gigs_Content_List">
                {gigs.map((gig, index) => (
                  index % 2 === 0 ? (
                    <div key={index} className="Gigs_ContentList_Element_Right">
                      <div className="Gigs_ContentList_Element_Content">{gig.title}<br /><p className="Gigs_ContentList_Element_Content_Desc">{gig.description}</p></div>
                      <div className="Gigs_ContentList_Element_Header">{gig.date}<br />{gig.time} Uhr</div>
                    </div>
                  ) : (
                    <div key={index} className="Gigs_ContentList_Element_Left">
                      <div className="Gigs_ContentList_Element_Header">{gig.date}<br />{gig.time} Uhr</div>
                      <div className="Gigs_ContentList_Element_Content">{gig.title}<br /><p className="Gigs_ContentList_Element_Content_Desc">{gig.description}</p></div>
                    </div>
                  )
                ))}
              </div>
            </div>
        </div>
    );

}

export default Gigs;