
export interface Song {
  title: string;
  description: string;
}

const songs: Song[] = [
  {
    title: 'Augustin Rap',
    description: 'Beschreibung für Element 1',
  },
  {
    title: 'Bossa Nova',
    description: 'Beschreibung für Element 2',
  },
  {
    title: 'Can you feel the love tonight',
    description: 'Beschreibung für Element 3',
  },
  {
      title: 'Christmas Lullaby',
      description: 'Beschreibung für Element 3',
  },
  {
    title: 'Der Herr segne und behüte dich',
    description: 'Beschreibung für Element 3',
  },
  {
    title: 'Dies ist ein großer Tag',
    description: 'Beschreibung für Element 3',
  },
  {
    title: 'Dudabda',
    description: 'Beschreibung für Element 3',
  },
  {
    title: 'Ein lieber Mensch geht fort von hier',
    description: 'Beschreibung für Element 3',
  },
  {
      title: 'Ein Likörchen für das Frauenchörchen',
      description: 'Beschreibung für Element 1',
    },
    {
      title: 'Es werd scho glei dumpa',
      description: 'Beschreibung für Element 2',
    },
    {
      title: 'Folgen wir den Hirten',
      description: 'Beschreibung für Element 3',
    },
    {
        title: 'Gabriellas Song',
        description: 'Beschreibung für Element 3',
    },
    {
      title: 'Gesang der Engel',
      description: 'Beschreibung für Element 3',
    },
    {
      title: 'Happy Xmas (War is over)',
      description: 'Beschreibung für Element 3',
    },
    {
      title: 'Heaven is a wonderful place',
      description: 'Beschreibung für Element 3',
    },
    {
      title: 'Hebe deine Augen auf',
      description: 'Beschreibung für Element 3',
    },
    {
        title: 'Hey Jude',
        description: 'Beschreibung für Element 1',
      },
      {
        title: 'Hör in den Klang der Stille',
        description: 'Beschreibung für Element 2',
      },
      {
        title: 'Hörst du den Vogelsang',
        description: 'Beschreibung für Element 3',
      },
      {
          title: 'Hört ihr nicht die Engel singen',
          description: 'Beschreibung für Element 3',
      },
      {
        title: 'Hungriges Herz',
        description: 'Beschreibung für Element 3',
      },
      {
        title: 'Ich will meine Traumfigur',
        description: 'Beschreibung für Element 3',
      },
      {
        title: 'Immer wenn es Weihnacht wird',
        description: 'Beschreibung für Element 3',
      },
      {
        title: 'I have got a feeling',
        description: 'Beschreibung für Element 3',
      },
      {
          title: 'Jetzt gehts los',
          description: 'Beschreibung für Element 1',
        },
        {
          title: 'Joyful joy',
          description: 'Beschreibung für Element 2',
        },
        {
          title: 'Kriminaltango',
          description: 'Beschreibung für Element 3',
        },
        {
            title: 'Let my light shine bright',
            description: 'Beschreibung für Element 3',
        },
        {
          title: 'Letzte Rose',
          description: 'Beschreibung für Element 3',
        },
        {
          title: 'Licht des Lebens',
          description: 'Beschreibung für Element 3',
        },
        {
          title: 'Mary had a baby',
          description: 'Beschreibung für Element 3',
        },
        {
          title: 'Music of the night',
          description: 'Beschreibung für Element 3',
        },
        {
            title: 'Na Tennes Tusen Julelys',
            description: 'Beschreibung für Element 1',
          },
          {
            title: 'Neigen sich die Stunden',
            description: 'Beschreibung für Element 2',
          },
          {
            title: 'Neue Zeiten alte Weihnacht',
            description: 'Beschreibung für Element 3',
          },
          {
              title: 'Nun will der Lenz uns grüßen',
              description: 'Beschreibung für Element 3',
          },
          {
            title: 'O selige Nacht',
            description: 'Beschreibung für Element 3',
          },
          {
            title: 'O Stunde die das Heil gebracht',
            description: 'Beschreibung für Element 3',
          },
          {
            title: 'On my own',
            description: 'Beschreibung für Element 3',
          },
          {
            title: 'Once again',
            description: 'Beschreibung für Element 3',
          },
          {
            title: 'One still and silent night',
            description: 'Beschreibung für Element 3',
          },
          {
            title: 'Perhaps love',
            description: 'Beschreibung für Element 3',
          },
          {
            title: 'Puttin on my ragtime shoes',
            description: 'Beschreibung für Element 3',
          },
          {
            title: 'Schuld war nur der Bossa Nova',
            description: 'Beschreibung für Element 3',
          },
          {
            title: 'Sing to the Lord Alleluja',
            description: 'Beschreibung für Element 3',
          },
          {
            title: 'Singen',
            description: 'Beschreibung für Element 3',
          },
          {
            title: 'I will follow him',
            description: 'Beschreibung für Element 3',
          },
          {
            title: 'Stille Nacht',
            description: 'Beschreibung für Element 3',
          },
          {
            title: 'Thank you for the music',
            description: 'Beschreibung für Element 3',
          },
          {
          title: 'The Entertainer',
          description: 'Beschreibung für Element 3',
          },
          {
          title: 'The Rose',
          description: 'Beschreibung für Element 3',
          },
          {
          title: 'Tochter Zion',
          description: 'Beschreibung für Element 3',
          },
          {
          title: 'Unser Vater',
          description: 'Beschreibung für Element 3',
          },
          {
          title: 'Vom Flügel eines Engels berührt',
          description: 'Beschreibung für Element 3',
          },
          {
          title: 'Von guten Mächten wunderbar geborgen',
          description: 'Beschreibung für Element 3',
          },
          {
            title: 'Weit Weit Weg',
            description: 'Beschreibung für Element 3',
          },
          {
          title: 'Wie schön du bist',
          description: 'Beschreibung für Element 3',
          },
          {
          title: 'Willkommen bienvenue welcome',
          description: 'Beschreibung für Element 3',
          },
          {
          title: 'You raise me up',
          description: 'Beschreibung für Element 3',
          },
          {
          title: 'You will be my heart',
          description: 'Beschreibung für Element 3',
          },
          {
          title: 'Zwischen Himmel und Erde',
          description: 'Beschreibung für Element 3',
          },

];

export default songs;
