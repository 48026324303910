import React from 'react';
import './News.css';
import NavigationBar from '../NavigationBar';

function News(){

    return(
        <div className="News_MainPage">
        <div className="News_BackgroundLayer">
            <NavigationBar />
            <div className="News_Header">
                <div className="News_Header_Upper"></div>
                <div className="News_Header_Lower">
                    <div className="News_Header_Lower_Text">Aktuelles</div>
                </div>
            </div>

            <div className="NewsContent">
                <div className="NewsContent_2024">
                    <p className= "NewsContent_Header">Juni 2024</p>
                    <p className= "NewsContent_Text_j24">
                        <div>
                        Aufgrund der unvorhersehbaren Wetterereignisse der letzen Wochen, spontanen Stürmen und Regengüssen, sowie Unwetterwarnungen seitens diverser offizieller Stellen müssen
                        wir das Mitsommerfest am 21.06.2024 schweren Herzens absagen. Obwohl wir in großer Vorfreude schon Vorbereitungen für ein gemütliches Beisammensein getroffen haben, steht die Sicherheit und
                        das Wohlergehen aller Beteiligten natürlich an erster Stelle. Wir bitten um Euer Verständnis und freuen uns auf die nächste Gelegenheit mit Euch zu feiern.
                        </div>
                        <div className="NewsContent_J24_Pic1"></div>
                    </p>
                    <p className= "NewsContent_Header">2024</p>
                    <p className= "NewsContent_Text">
                        Dieses Jahr lassen wir es ruhig angehen. Keine Konzerte geplant. Das gibt uns die Möglichkeit, zurückgestellte Lieder einzustudieren, um für die nächsten Zeiten gerüstet zu sein. Der Fokus in diesem Jahr liegt auf der Gewinnung neuer Sängerinnen. Das müssen wir in diesem Jahr intensiv angehen.
                        Eventuell können wir am diesjährigen Schlossfest einen Beitrag zum leiblichen Wohl der Gäste einbringen.
                        Für nächstes Jahr sammeln wir bereits Ideen, wie wir unseren Freunden und Gästen wieder ein besonderes Erlebnis bieten können. Seid gespannt…
                    </p>
                    <p className= "NewsContent_Header">November 2023</p>
                    <p className= "NewsContent_Text">
                        Das Weihnachtskonzert in der Katholischen Kirche in Dallau steht an. Die zur Aufführung kommenden Stücke werden intensiv geprobt. Gemeinsame Chorproben mit den Männern des MGV durchgeführt und dann ist es soweit. Unsere Erwartungen werden mehr als erfüllt.
                        Viele Zuhörer haben den Weg in die Kirche gefunden und der Bürgermeister mit Frau ist da. Das Konzert wird ein großer Erfolg. Der befreundete Verein Sängerbund Schwarzach, mit dem über den Chorleiter des MGV, Jochen Thurn eine gute Verbindung besteht, hat tolle Liedbeiträge mitgebracht. Ein super Konzert, das die Mühen wert war.
                        Vor allem das gemeinsam mit dem Männergesangverein vorgetragene Abschlusslied „Friede auf Erden“ hinterlässt einen bleibenden Eindruck.
                    </p>
                    <div className="NewsContent_Pic_ContainerN23">
                        <div className="NewsContent_N23_Pic1"></div>
                        <div className="NewsContent_N23_Pic2"></div>
                        <div className="NewsContent_N23_Pic3"></div>
                        <div className="NewsContent_N23_Pic4"></div>
                        <div className="NewsContent_N23_Pic5"></div>
                     </div>
                    <p className= "NewsContent_Header">Juli 2023</p>
                    <p className= "NewsContent_Text">
                       Die Vorbereitungen der 3. Dallauer Musiknacht am 15. Juli 2023 sind in vollem Gange.  Es ist schön zu sehen, dass alle mit anpacken. Das macht eine gesunde Gemeinschaft aus.
                       Die Essens- und Getränkestände werden aufgebaut und die Beleuchtung und Bühnentechnik installiert. Wie schon bereits davor haben wir wieder zahlreiche Helfer aus dem Männergesangverein sowie Mitarbeiter des örtlichen Bauhofs, die uns beim Bühnenaufbau und herrichten des Schlossplatzes tatkräftig unterstützen.
                    </p>
                    <div className="NewsContent_Pic_ContainerJ23">
                        <div className="NewsContent_J23_Pic1"></div>
                        <div className="NewsContent_J23_Pic2"></div>
                        <div className="NewsContent_J23_Pic3"></div>
                        <div className="NewsContent_J23_Pic4"></div>
                    </div>

                </div>

            </div>

        </div>
        </div>
    );

}

export default News;