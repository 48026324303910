import React, { useState, useEffect } from 'react';
import './App.css';
import NavigationBar from './NavigationBar';

function App() {
    const [scrollPosition, setScrollPosition] = useState(0);

    useEffect(() => {
        const handleScroll = () => {
            const position = window.pageYOffset;
            setScrollPosition(position);
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const calculateOpacity = () => {
        const currentPosInVh = scrollPosition / window.innerHeight * 100;
        return currentPosInVh / 100;
    };

    return (
        <div className="App">
            <div className="MainPage_GroupPicture">
                <NavigationBar />
                <div className="Mobile_BG">
                <div className="MainPage_HeadingText">
                    Singing Sisters <br />
                    <div className="MainPage_HeadingSubText">Dallauer Frauenchor </div>
                </div>
                </div>
                <div className="MainPage_IntroductionArea" style={{ opacity: calculateOpacity() }}>
                    <div className="MainPage_IntroductionArea_Video">
                        <div className="MainPage_IntroductionArea_Video_Content">
                            <div className="MainPage_IntroductionArea_Content_Header">Nacht der Musik</div>
                            <div className="MainPage_IntroductionArea_Content_Text">Ein Ausschnitt des Open Air - Konzerts "Musiknacht Dallau" im Sommer 2023</div>
                            <div className="MainPage_IntroductionArea_Content_Embed">
                                <iframe width="80%" height="100%" src="https://www.youtube-nocookie.com/embed/ozomuIZ4ki4?si=wyTG-kWzyL_ayV8e" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                            </div>
                        </div>
                    </div>
                    <div className="MainPage_IntroductionArea_JoinIn">
                        <div className="MainPage_IntroductionArea_Content_Header">Sing mit Uns</div>
                        <div className="MainPage_IntroductionArea_Content_Text">Wir proben jeden Montag Abend im Gemeindehaus Dallau. <br /> Gerne kannst Du bei Interesse vorbeikommen und mit uns singen.</div>
                        <div className="MainPage_IntroductionArea_Content_Mic"></div>
                    </div>
                </div>
                <div className="MainPage_ContactArea">
                    <div className="MainPage_ContactArea_Join">
                        <div className="MainPage_ContactArea_Join_Header">Mitsingen</div>
                        <div className="MainPage_ContactArea_Join_Text">
                            <p>
                                Wir beginnen zur Zeit ein neues Programm und freuen uns über neue Mitsängerinnen in allen Stimmgruppen (Sopran 1/2, Alt 1/2).
                                Die Proben finden jeden Montag um 19:30 Uhr im Gemeindehaus in Elztal-Dallau statt.
                            </p>
                            <p>Komm gerne zu einer Schnupperstunde vorbei und erlebe Chorgesang der Spaß macht!</p>
                        </div>
                    </div>
                    <div className="MainPage_ContactArea_Newsletter">
                        <div className="MainPage_ContactArea_Newsletter_Header">Abonnieren Sie unseren Newsletter</div>
                        <div className="MainPage_ContactArea_Newsletter_Text">
                             <p>
                                Um aktuelle Informationen zu unserem Programm, den Singstunden, sowie
                                anstehenden Konzerten und Auftritten zu erhalten, abonnieren Sie gerne unseren Newsletter.
                             </p>
                             <div className="MainPage_ContactArea_Newsletter_Input">
                               <div className="input-container">
                                   <span>Ihre E-Mail-Adresse</span>
                                   <input type="text" placeholder="example@example.com" />
                               </div>
                                <button className="subscribe-btn">Newsletter abonnieren</button>
                             </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default App;
